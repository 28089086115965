define("parro-web/utils/mobileUtil", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = MobileUtil;

  function MobileUtil() {}

  MobileUtil.isMobileDevice = function () {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  MobileUtil.getMobileDevice = function () {
    const ua = navigator.userAgent;
    return /IEMobile|Windows Phone|Lumia/i.test(ua) ? 'WINDOWS' : /iPhone|iP[oa]d/.test(ua) ? 'IOS' : /Android/.test(ua) ? 'ANDROID' : /BlackBerry|PlayBook|BB10/.test(ua) ? 'BLACKBERRY' : /webOS|Mobile|Tablet|Opera Mini|\bCrMo\/|Opera Mobi/i.test(ua) ? 'UNDETECTED_DEVICE' : 'NOT_MOBILE_TABLET';
  };

  MobileUtil.terminateYearbookWebview = function () {
    try {
      closeWebView.postMessage('');
    } catch (e) {}

    try {
      window.flutter_inappwebview.callHandler('closeWebView');
    } catch (e) {}
  };

  MobileUtil.showYearbookError = function () {
    try {
      showError.postMessage('');
    } catch (e) {}

    try {
      window.flutter_inappwebview.callHandler('showError');
    } catch (e) {}
  };

  MobileUtil.hideWebviewHeader = function () {
    try {
      hideAppbar.postMessage('');
    } catch (e) {}

    try {
      window.flutter_inappwebview.callHandler('hideAppbar');
    } catch (e) {}
  };

  MobileUtil.isShowContent = function () {
    const mediaQuery = window.matchMedia('(min-width: 960px)');

    if (mediaQuery.matches) {
      return true;
    }

    return (0, _utils.isPresent)(document.querySelector('body.is-showcontent'));
  };
});